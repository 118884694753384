var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c("v-text-field", {
        ref: _vm.scannerPayload.attrRef,
        staticClass: "pa-0",
        attrs: {
          solo: "",
          dense: "",
          "hide-details": "",
          "prepend-inner-icon": "mdi-qrcode-scan",
          autocomplete: "off",
          placeholder: _vm.$t(_vm.scannerPayload.placeholder),
          id: _vm.scannerPayload.attrId,
          disabled: _vm.disabled !== undefined ? _vm.disabled : false,
          loading: _vm.loading !== undefined ? _vm.loading : false,
          "append-icon": _vm.searchTerm ? "mdi-magnify" : "",
        },
        on: {
          "click:prepend-inner": _vm.scanning,
          click: _vm.selectContent,
          "click:append": _vm.scannerPayload.change,
          change: _vm.splitOnChange,
          input: _vm.debouncedSearchBarcode,
        },
        model: {
          value: _vm.searchTerm,
          callback: function ($$v) {
            _vm.searchTerm = $$v
          },
          expression: "searchTerm",
        },
      }),
      _c(
        "v-dialog",
        {
          staticClass: "mt-0",
          attrs: { persistent: "", "max-width": "500px" },
          model: {
            value: _vm.scanDialog,
            callback: function ($$v) {
              _vm.scanDialog = $$v
            },
            expression: "scanDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  " " + _vm._s(_vm.$t(_vm.scannerPayload.placeholder)) + " "
                ),
              ]),
              _c("v-divider"),
              _c("v-card-text", { staticClass: "pa-1" }, [
                _vm._v(" " + _vm._s(_vm.scanError) + " "),
                _c("div", [
                  _c("video", {
                    ref: "video",
                    staticStyle: {
                      border: "1px solid gray",
                      width: "100%",
                      height: "auto",
                    },
                    attrs: { id: "video" },
                  }),
                ]),
                _vm.videoInputDevices.length > 1
                  ? _c(
                      "div",
                      { attrs: { id: "sourceSelectPanel" } },
                      [
                        _c("v-select", {
                          attrs: {
                            id: "sourceSelect",
                            outlined: "",
                            items: _vm.videoInputDevices,
                            "hide-details": "",
                            "item-text": "label",
                            "item-value": "deviceId",
                            label: _vm.$t("message.common.changeVideoSource"),
                          },
                          model: {
                            value: _vm.selectedDeviceId,
                            callback: function ($$v) {
                              _vm.selectedDeviceId = $$v
                            },
                            expression: "selectedDeviceId",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", id: "scanClose" },
                      on: { click: _vm.stopScanningSession },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.close")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }